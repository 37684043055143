import { ChangeDetectionStrategy, Component, effect, inject, Injector, OnInit, signal, WritableSignal } from '@angular/core';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';

import { BehaviorSubject, filter } from 'rxjs';

import { select } from '@ngrx/store';
import { PageMetasSelectors } from '@store/page-metas';

import { LanguageService } from '@services/language/language.service';

import { IconComponent } from '@components/common/icon/icon.component';
import { LinkComponent } from '@components/common/link/link.component';
import { ButtonComponent } from '@components/common/_buttons/button/button.component';
import { ReactiveComponent } from '@core/components/base/reactive-component';

import { MainNav } from '@interfaces/config';
import { LanguageOption, LANGUAGES } from '@core/config';
import { cloneDeep } from 'lodash-es';

@Component({
  standalone: true,
  selector: 'sps-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, IconComponent, LinkComponent, ButtonComponent, TranslateModule],
})
export class MobileNavComponent extends ReactiveComponent implements OnInit {
  public $active: WritableSignal<boolean> = signal(false);
  public config: MainNav.ComponentConfig = null;
  public languages: LanguageOption[] = LANGUAGES;

  private history: MainNav.ComponentConfig[] = [];
  private originalConfig$: BehaviorSubject<MainNav.ComponentConfig> = new BehaviorSubject(null);
  private document: Document = inject(DOCUMENT, { optional: true });
  private router = inject(Router);
  private injector = inject(Injector);

  constructor() {
    super();
    this.subscribeToRouterEvents();
    this.subscribeToConfig();
  }

  ngOnInit(): void {
    effect(
      () => {
        if (this.$active()) {
          this.reset();
        }
        this.toggleBodyClass();
      },
      { injector: this.injector }
    );
  }

  get depths(): number {
    return this.history.length;
  }

  get active(): boolean {
    return this.$active();
  }

  set active(active: boolean) {
    this.$active.set(active);
  }

  get currentLanguage(): LanguageOption {
    return this.languages.find(lang => lang.value === LanguageService.selectedLanguage);
  }

  get contactUsPath(): string {
    if (LanguageService.selectedLanguage?.toLowerCase() === 'de') {
      return '/de/kontakt';
    }

    return '/en/contact-us';
  }

  public selectItem(item: MainNav.ConfigItem = null): void {
    this.history.push(this.config);

    this.config = {
      selectedItem: item.label,
      description: item.description,
      items: item.children,
      cta: item.cta,
      secondcta: item.secondcta
    };
  }

  public back(): void {
    this.config = this.history.pop();
  }

  public toggleActive(): void {
    this.$active.set(!this.$active());
  }

  private subscribeToRouterEvents(): void {
    const $navEvent = toSignal(this.router.events);

    effect(
      () => {
        if ($navEvent() instanceof NavigationStart) {
          this.$active.set(false);
        }
      },
      { allowSignalWrites: true }
    );
  }

  private subscribeToConfig(): void {
    this.store
      .pipe(
        select(PageMetasSelectors.selectCurrentMainNavConfig),
        filter(config => !!config?.length)
      )
      .subscribe(config => {
        this.history = [];
        this.config = {  selectedItem: null, items: config };
        this.originalConfig$.next(cloneDeep(this.config));
      });
  }

  private reset(): void {
    this.config = { ...this.originalConfig$.value };
    this.history = [];
  }

  private toggleBodyClass(active: boolean = this.$active()): void {
    if (this.isServer || !this.document?.body) {
      return;
    }

    this.document.body.classList.toggle('no-scroll', active);
  }
}
